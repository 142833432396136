// ANIMATION - sidebar slide out
@keyframes appSidebarMobileSlideOutLeft {
  from {
    left: 0;
  }
  to {
    left: -$app-sidebar-width;
  }
}
@keyframes appSidebarMobileSlideOutRight {
  from {
    right: 0;
  }
  to {
    right: -$app-sidebar-width;
  }
}

// ANIMATION - wide sidebar slide out
@keyframes appSidebarWideMobileSlideOutLeft {
  from {
    left: 0;
  }
  to {
    left: -$app-sidebar-wide-width;
  }
}
@keyframes appSidebarWideMobileSlideOutRight {
  from {
    right: 0;
  }
  to {
    right: -$app-sidebar-wide-width;
  }
}

// ANIMATION - remove mobile backdrop
@keyframes appSidebarMobileRemoveBackdrop {
  from {
    background: $app-sidebar-mobile-backdrop-bg;
  }
  to {
    background: transparent;
  }
}

.app-sidebar-mobile-closed {
  @include media-breakpoint-down(md) {
    & .app-sidebar:not(.app-sidebar-end),
    & .app-sidebar-bg:not(.app-sidebar-end) {
      @if $enable-rtl {
        right: -$app-sidebar-width;
        animation: appSidebarMobileSlideOutRight 0.2s ease;
      } @else {
        left: -$app-sidebar-width;
        animation: appSidebarMobileSlideOutLeft 0.2s ease;
      }
    }
    & .app-sidebar-mobile-backdrop:not(.app-sidebar-end) {
      display: block;
      background: none;
      animation: appSidebarMobileRemoveBackdrop 0.2s ease;
    }

    &.app-with-wide-sidebar {
      & .app-sidebar:not(.app-sidebar-end),
      & .app-sidebar-bg:not(.app-sidebar-end) {
        @if $enable-rtl {
          right: -$app-sidebar-wide-width;
          animation: appSidebarWideMobileSlideOutRight 0.2s ease;
        } @else {
          left: -$app-sidebar-wide-width;
          animation: appSidebarWideMobileSlideOutLeft 0.2s ease;
        }
      }
    }
  }
}
