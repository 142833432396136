.widget {
  overflow: hidden;
  margin-bottom: rem(20px);
  background: var(--app-component-bg);
  color: inherit;
  padding: 0;

  & .widget-header {
    border-bottom: 1px solid var(--app-component-border-color);
    color: var(--app-component-color);
    display: flex;
    align-items: center;

    & .widget-header-title {
      margin: 0;
      font-size: $font-size-base;
      padding: $spacer * 0.66 $spacer;
      flex: 1;
    }
    & .widget-header-icon {
      padding: $spacer * 0.66 $spacer;

      & + .widget-header-icon {
        @if $enable-rtl {
          padding-right: 0;
        } @else {
          padding-left: 0;
        }
      }
    }
  }
  & .widget-body {
    padding: $spacer;
  }
}
