.app-sidebar {
  width: $app-sidebar-width;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: $app-header-height;
  background: var(--app-sidebar-bg);
  z-index: $app-sidebar-zindex;

  @if $enable-rtl {
    left: auto;
    right: 0;
  }
  @include media-breakpoint-down(md) {
    position: fixed;
    padding-top: 0;
    left: $app-sidebar-width * -1;
    z-index: $app-header-zindex + 1;

    @if $enable-rtl {
      left: auto;
      right: $app-sidebar-width * -1;
    }
  }

  & .menu {
    & .menu-profile {
      padding: $app-sidebar-component-padding-x;
      color: var(--app-sidebar-profile-color);
      background: var(--app-sidebar-profile-bg);
      overflow: hidden;
      position: relative;

      & .menu-profile-link {
        margin: -$app-sidebar-component-padding-x;
        padding: $app-sidebar-component-padding-x;
        display: block;
        color: var(--app-sidebar-profile-color);
        font-weight: $font-weight-bold;
        text-decoration: none;

        &:hover {
          & .menu-profile-cover {
            &.with-shadow {
              &:before {
                opacity: 0.75;
              }
            }
          }
        }
      }
      & .menu-profile-image {
        width: rem(34px);
        height: rem(34px);
        margin-bottom: rem(10px);
        overflow: hidden;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        border-radius: $border-radius-lg * 5;

        & img {
          max-width: 100%;
          max-height: 100%;
        }
        &.menu-profile-image-icon {
          & i {
            float: none;
            display: block;
            font-size: rem(24px);
            text-align: center;
            width: auto;
            margin: 0 auto rem(-12px);
          }
        }
      }
      & .menu-profile-cover {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: $app-sidebar-profile-bg-image;
        background-repeat: no-repeat;
        background-size: cover;

        &.with-shadow {
          &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(var(--app-sidebar-profile-bg-rgb), 0.75);
            transition: all 0.2s linear;
          }
        }
      }
      & .menu-profile-info {
        font-size: $font-size-lg;
        position: relative;

        & small {
          display: block;
          font-weight: $font-weight-base;
          color: rgba(var(--app-sidebar-profile-color-rgb), 0.75);
          font-size: $font-size-base;
          margin-bottom: rem(-5px);
        }
      }
    }
    & .menu-header {
      margin: 0;
      padding: rem(15px) rem(20px) rem(3px);
      line-height: rem(20px);
      font-size: rem(11px);
      color: var(--app-sidebar-menu-header-color);
      font-weight: $font-weight-bold;
    }
    & .menu-divider {
      height: 1px;
      background-color: var(--app-sidebar-menu-divider-bg);
      margin: $app-sidebar-component-padding-y 0;
    }
    & .menu-caret {
      display: block;
      width: rem(20px);
      text-align: center;
      font-size: $font-size-sm;
      border: none;

      @include fontawesome();
      @if $enable-rtl {
        margin-right: auto;
      } @else {
        margin-left: auto;
      }

      &:before {
        content: "\f054";
        display: block;
        text-align: center;
        opacity: 0.5;
        transition: all 0.2s linear;

        @if $enable-rtl {
          content: "\f053";
        }
      }
    }
    & .menu-search {
      padding: $app-sidebar-component-padding-x;

      & + .menu-item {
        padding-top: 0;
      }
      & .form-control {
        background: none;
        border: none;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid
          rgba(var(--app-sidebar-component-color-rgb), 0.2);
        color: var(--app-sidebar-component-color);
        border-radius: 0;

        &:focus {
          border-color: rgba(var(--app-sidebar-component-color-rgb), 0.5);
          box-shadow: none;
        }
      }
    }

    & .active:not(.closed):not(.closing),
    & .expanding,
    & .expand {
      & > a {
        & .menu-caret {
          &:before {
            opacity: 1;

            @if $enable-rtl {
              transform: rotate(-90deg);
            } @else {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
    & .menu-item {
      position: relative;

      & .menu-link {
        padding: $app-sidebar-component-padding-y
          $app-sidebar-component-padding-x;
        line-height: rem(20px);
        color: rgba(var(--app-sidebar-component-color-rgb), 0.6);
        text-decoration: none;
        display: flex;
        align-items: center;

        & .menu-text {
          flex: 1;
        }
        & .menu-badge {
          padding: 0 $badge-padding-x * 1.5;
          background: var(--app-sidebar-menu-badge-bg);
          font-weight: $font-weight-bold;
          font-size: rem(10px);
          border-radius: $border-radius-lg * 2;
        }
        & .menu-label {
          padding: $badge-padding-y $badge-padding-x;
          font-size: 75%;
          font-weight: $font-weight-bold;
          color: var(--app-theme-color);
          text-align: center;
          white-space: nowrap;
          vertical-align: baseline;
          margin-left: rem(5px);
          background-color: var(--app-theme);
          border-radius: $border-radius;

          @include gradient-enabled {
            background-image: var(--bs-gradient);
          }
          @if $enable-rtl {
            margin-left: 0;
            margin-right: rem(5px);
          }
        }
        &:hover,
        &:focus {
          background: none;
          color: var(--app-sidebar-component-color);
        }
      }
      & .menu-icon {
        width: rem(14px);
        text-align: center;
        line-height: rem(20px);
        font-size: $font-size-lg;
        display: flex;
        align-items: center;
        justify-content: center;

        @if $enable-rtl {
          margin-left: rem(10px);
        } @else {
          margin-right: rem(10px);
        }
        & img {
          max-width: 100%;
          max-height: 100%;
        }
        & [class^="ion-"],
        & ion-icon {
          width: rem(18px);
          height: rem(18px);
          font-size: rem(18px);
          display: block;
          margin: 0 rem(-2px);
        }
      }
      & .menu-icon-img {
        margin-top: rem(3px);
        margin-bottom: rem(3px);
        width: rem(14px);
        height: rem(14px);
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: $border-radius;
        display: flex;
        align-items: center;

        @if $enable-rtl {
          margin-left: rem(10px);
        } @else {
          margin-right: rem(10px);
        }

        & img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: 0 auto;
        }
      }
      & .menu-submenu {
        padding: rem(5px) 0 rem(10px) rem(39px);
        margin: 0;
        position: relative;
        display: none;

        @if $enable-rtl {
          padding-left: 0;
          padding-right: rem(39px);
        }

        & .menu-item {
          & .menu-link {
            padding: rem(3px) rem(20px) rem(3px) rem(15px);
            color: rgba($white, 0.45);
            text-decoration: none;
            position: relative;
            line-height: rem(18px);

            @if $enable-rtl {
              padding-right: rem(15px);
              padding-left: rem(20px);
            }

            &:hover,
            &:focus {
              color: $white;
            }
            &:after {
              content: "";
              position: absolute;
              left: rem(-11px);
              top: rem(11px);
              width: rem(11px);
              height: rem(2px);
              background: var(--app-sidebar-active-component-bg);

              @if $enable-rtl {
                right: rem(-11px);
                left: auto;
              }
            }
          }

          &:before {
            content: "";
            position: absolute;
            left: rem(-13px);
            top: 0;
            bottom: 0;
            width: rem(2px);
            background: var(--app-sidebar-active-component-bg);

            @if $enable-rtl {
              right: rem(-13px);
              left: auto;
            }
          }
          &:after {
            content: "";
            position: absolute;
            left: 0;
            width: rem(6px);
            height: rem(6px);
            border: 1px solid rgba(var(--app-sidebar-component-color-rgb), 0.6);
            top: rem(11px);
            margin-top: rem(-2px);
            z-index: 10;
            background: var(--app-sidebar-bg);
            border-radius: rem(4px);

            @if $enable-rtl {
              left: auto;
              right: 0;
            }
          }
          &:first-child {
            &:before {
              top: rem(-14px);
            }
            &:last-child:before {
              height: rem(27px);
            }
          }
          &:last-child {
            &:before {
              bottom: auto;
              height: rem(13px);
            }
          }
          &.has-sub {
            & > a {
              &:before {
                background: var(--app-sidebar-active-component-bg);
              }
            }
          }
          &.active {
            & > .menu-link {
              background: none;
              color: var(--app-sidebar-component-color);
            }
            &:after {
              border-color: var(--app-theme);
            }
          }

          & .menu-submenu {
            padding: 0 0 0 rem(15px);
            background: none;

            @if $enable-rtl {
              padding-left: 0;
              padding-right: rem(15px);
            }
          }
        }
      }

      &.active {
        &.has-sub {
          & > .menu-submenu {
            display: block;
          }
        }
        & > .menu-link {
          position: relative;
          z-index: 10;
          color: $white;
          background-color: var(--app-sidebar-active-component-bg);

          @include gradient-enabled {
            color: var(--app-theme-color);
            background-color: var(--app-theme);
            background-image: var(--bs-gradient);
          }

          & .menu-icon {
            color: var(--app-theme);

            @include gradient-enabled {
              color: rgba($black, 0.65);
            }
          }
          & .menu-caret {
            @include gradient-enabled {
              color: rgba($black, 0.5);
            }
          }
          & .menu-badge {
            background: rgba($black, 0.65);
          }
          & .menu-label {
            @include gradient-enabled {
              color: $white;
              background: rgba($black, 0.65);
            }
          }
        }
      }
    }
  }

  & .app-sidebar-content {
    position: relative;
  }
  & .app-sidebar-minify-btn {
    margin: rem(10px) 0;
    padding: rem(5px) rem(20px) rem(5px) rem(10px) !important;
    background: var(--app-sidebar-minify-btn-bg);
    color: var(--app-sidebar-component-color);

    @if $enable-rtl {
      border-radius: 0 $border-radius * 5 $border-radius * 5 0;
    } @else {
      border-radius: $border-radius * 5 0 0 $border-radius * 5;
    }

    & i {
      margin: 0 !important;
      color: var(--app-sidebar-component-color);
    }
    &:hover,
    &:focus {
      background: var(--app-sidebar-minify-btn-hover-bg);
    }
  }
  &.app-sidebar-grid {
    & .menu {
      & > .menu-item {
        & + .menu-item {
          margin-top: -1px;
        }
        & > .menu-link {
          border-top: 1px solid lighten($dark, 7.5%);
          border-bottom: 1px solid lighten($dark, 7.5%);
        }

        &.expanding,
        &.expand {
          & > a {
            border-bottom-color: transparent;
          }
        }
        &.active {
          & > .menu-link {
            border-color: $gray-800;
          }
        }
      }
    }
  }
  &.app-sidebar-transparent {
    background: none;

    & + .app-sidebar-bg {
      background-image: var(--app-sidebar-transparent-bg-image);
      background-size: cover;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(var(--app-sidebar-bg-rgb), 0.75);
      }
    }
    & .menu {
      & > .menu-item {
        &.active {
          & > .menu-link {
            background: rgba($black, 0.3);
          }
        }
        &.menu-profile {
          background: rgba($black, 0.25);

          & a {
            &:hover,
            &:focus {
              background: none;
            }
          }
          & .menu-profile-cover {
            background: none;

            &.with-shadow {
              &:before {
                background: none;
              }
            }
          }
          &.active {
            background: rgba($black, 0.25);
          }
        }
      }
    }
    & .menu-submenu {
      background: none;

      & > .menu-item {
        &:before {
          background: rgba($black, 0.45);
        }
        & > .menu-link {
          &:after {
            background: rgba($black, 0.45);
          }
        }
        &.has-sub {
          & > .menu-link {
            &:after {
              background: rgba($black, 0.45);
            }
          }
        }
      }
    }
  }
}
.app-sidebar-bg {
  background: var(--app-sidebar-bg);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: $app-sidebar-width;
  z-index: $app-sidebar-zindex - 10;

  @if $enable-rtl {
    left: auto;
    right: 0;
  }
  @include media-breakpoint-down(md) {
    padding-top: 0;
    left: -$app-sidebar-width;
    z-index: $app-header-zindex;

    @if $enable-rtl {
      left: auto;
      right: -$app-sidebar-width;
    }
  }
}
.app-sidebar-mobile-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $app-sidebar-mobile-backdrop-zindex;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 0;
  margin: 0;
  outline: none;
  display: none;
}
