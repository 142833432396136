.select2 {
  @if $enable-rtl {
    direction: rtl;
  }

  &.select2-container {
    display: block;
    background: var(--app-component-bg);

    & .selection {
      & .select2-selection {
        background: var(--app-component-bg);

        &.select2-selection--single,
        &.select2-selection--multiple {
          border-color: var(--app-component-border-color);
          outline: 0;
          height: $input-height;
          display: flex;
          align-items: center;

          & .select2-selection__rendered {
            line-height: 1;
            padding: $input-padding-y $input-padding-x;
            font-weight: $font-weight-bold;
            color: var(--app-component-color);
            position: relative;
            display: flex;
            flex-wrap: wrap;
            flex: 1;

            @if $enable-rtl {
              padding-left: $input-padding-x;
            } @else {
              padding-right: $input-padding-x;
            }

            & .select2-selection__clear {
              position: absolute;
              top: 0;
              bottom: 0;
              width: $input-padding-x;
              display: flex;
              align-items: center;
              justify-content: center;

              @if $enable-rtl {
                left: 0;
              } @else {
                right: 0;
              }
            }
          }
          & .select2-selection__arrow {
            height: auto;
            width: auto;
            position: relative;
            top: 0;
            right: 0;
            padding: $input-padding-y $input-padding-x;
            display: flex;
            align-items: center;

            & b {
              position: relative;
              top: 0;
              left: 0;
              margin: 0;
              border: none;
              color: var(--app-component-color);
              width: auto;
              height: auto;

              @include fontawesome();

              &:before {
                content: "\f0d7";
              }
            }
          }
        }
        &.select2-selection--multiple {
          height: auto;
          min-height: $input-height;

          & .select2-selection__rendered {
            padding: $input-padding-y - rem-default(1px) $input-padding-x
              $input-padding-y * 0.5 - rem-default(1px);

            & .select2-search--inline {
              float: none;
              min-width: rem(100px);

              & .select2-search__field {
                margin-top: -1px;
                margin-bottom: $input-padding-y * 0.5;
                font-weight: $input-font-weight;
                min-height: rem(20px);
                color: var(--app-component-color);

                &::placeholder {
                  color: $input-placeholder-color;
                }
              }
            }
          }
          & .select2-selection__choice {
            border: none;
            background: #{rgba(var(--app-component-border-color-rgb), 0.65)};
            margin-bottom: $input-padding-y * 0.5;
            margin-top: 0;
            padding: rem(4px) rem(8px);
            color: var(--app-component-color);
            float: none;
            position: relative;
            border-radius: $border-radius;

            @if $enable-rtl {
              padding-left: $input-padding-y * 3;
              margin-left: $input-padding-y * 0.5;
              margin-right: 0;
            } @else {
              padding-right: $input-padding-y * 3;
              margin-right: $input-padding-y * 0.5;
            }

            & .select2-selection__choice__remove {
              color: $gray-500;
              font-size: $font-size-lg;
              margin: 0;
              float: none;
              position: absolute;
              top: 0;
              bottom: 0;
              width: $input-padding-y * 3;
              display: flex;
              align-items: center;
              justify-content: center;

              @if $enable-rtl {
                left: 0;
              } @else {
                right: 0;
              }
            }
          }
        }
      }
    }

    &.select2-container--above {
      &.select2-container--open {
        & .selection {
          & .select2-selection {
            &.select2-selection--single {
              & .select2-selection__arrow {
                & b {
                  &:before {
                    content: "\f0d8";
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.select2-container {
  & .select2-dropdown {
    border: none;
    background: var(--app-component-dropdown-bg);
    box-shadow: $box-shadow;

    & .select2-search--dropdown {
      padding: $nav-link-padding-x * 0.5;

      & input {
        border-color: var(--app-component-border-color);
        height: $input-height;
        padding: $input-padding-y $input-padding-x;
        outline: none;
        color: var(--app-component-color);
        background-color: transparent;
        border-radius: $border-radius;
        transition: all 0.2s linear;

        &:focus {
          border-color: $input-focus-border-color;
          box-shadow: $input-focus-box-shadow;
        }
      }
    }
    & .select2-results {
      & .select2-results__options {
        & .select2-results__option {
          font-weight: $font-weight-bold;
          color: var(--app-component-color);

          & .select2-results__group {
            padding: $nav-link-padding-y $nav-link-padding-x * 0.5
              $nav-link-padding-y * 0.5;
            color: var(--app-component-color);
            font-size: $font-size-sm;
          }
          & .select2-results__option {
            padding: $nav-link-padding-y $nav-link-padding-x;
          }
          &.select2-results__option--highlighted {
            background: var(--app-component-hover-bg);
            color: var(--app-component-color);
          }
          &.select2-results__message {
            font-weight: $font-weight-bold;
            font-size: $font-size-base;
            color: rgba(var(--app-component-color-rgb), 0.5);
            padding: $nav-link-padding-y $nav-link-padding-x * 0.5;
          }
          &[aria-selected="true"] {
            background: $component-active-bg;
            color: $white;
          }
        }
      }
    }

    &.select2-dropdown--above {
      box-shadow: $box-shadow;
    }
  }
}
