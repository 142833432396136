.delivery_note {
  background: var(--app-component-bg);
  padding: rem(20px);

  & > div {
    &:not(.delivery_note-footer) {
      margin-bottom: rem(20px);
    }
  }
  & .delivery_note-company {
    font-size: rem(20px);
    font-weight: $font-weight-bold;
  }
  & .delivery_note-header {
    margin: 0 rem(-20px);
    background: var(--bs-light);
    display: flex;

    @include media-breakpoint-down(md) {
      display: block;

      & > div {
        & + div {
          border-top: 1px solid mix($black, $light, 5%);
        }
      }
    }
  }
  & .delivery_note-from {
    padding: rem(20px);
    flex: 1;

    & strong {
      font-size: rem(16px);
      font-weight: $font-weight-bold;
    }
  }
  & .delivery_note-to {
    padding: rem(20px);
    flex: 1;

    & strong {
      font-size: rem(16px);
      font-weight: $font-weight-bold;
    }
  }
  & .delivery_note-date {
    text-align: right;
    padding: rem(20px);
    flex: 1;

    @include media-breakpoint-down(md) {
      text-align: left;
    }

    & .date {
      font-size: rem(16px);
      font-weight: $font-weight-bold;
    }
  }
  & .delivery_note-price {
    background: var(--bs-light);
    width: 100%;
    display: flex;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    & small {
      font-size: rem(12px);
      font-weight: normal;
      display: block;
    }
    & .delivery_note-price-right {
      margin-left: auto;
      padding: rem(20px);
      font-size: rem(28px);
      font-weight: 300;
      position: relative;
      vertical-align: bottom;
      text-align: right;
      color: var(--bs-light);
      background: var(--bs-dark);
      min-width: 25%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & small {
        display: block;
        opacity: 0.6;
        position: absolute;
        top: rem(15px);
        left: rem(20px);
        font-size: rem(12px);
      }
    }
    & .delivery_note-price-left {
      padding: rem(20px);
      font-size: rem(20px);
      font-weight: $font-weight-bold;
      position: relative;
      vertical-align: middle;
      flex: 1;

      & .delivery_note-price-row {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          display: block;
          text-align: center;
        }

        & .sub-price {
          padding: 0 rem(20px);

          @include media-breakpoint-down(md) {
            padding: 0;

            & + .sub-price {
              padding-left: rem(20px);
            }
          }
          @include media-breakpoint-down(sm) {
            & + .sub-price {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  & .delivery_note-footer {
    //border-top: 1px solid var(--app-component-border-color);
    padding-top: rem(15px);
    //font-size: rem(11px);
    //color: rgba(var(--app-component-color-rgb), 0.5);
  }
  & .delivery_note-note {
    color: rgba(var(--app-component-color-rgb), 0.5);
    margin-top: rem(80px);
    font-size: rem(11px);
    line-height: 1.75;
  }
  & .table-delivery_note {
    font-size: rem(13px);

    @include media-breakpoint-down(md) {
      white-space: nowrap;
    }
  }
}
