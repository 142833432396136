.breadcrumb {
  margin: 0;
  font-weight: $breadcrumb-font-weight;

  & .breadcrumb-item {
    line-height: $page-header-line-height * $page-header-font-size;

    & + .breadcrumb-item {
      @if $enable-rtl {
        padding-left: 0;
        padding-right: 0.5rem;
      }
      &:before {
        @if $enable-rtl {
          padding-right: 0;
          padding-left: 0.5rem;
          float: right;
        }
      }
    }
    & a {
      color: var(--bs-body-color);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
