.widget-card {
  position: relative;
  margin-bottom: $spacer;
  display: block;
  text-decoration: none !important;

  & .widget-card-content {
    padding: $spacer;
    position: relative;
  }
  & .widget-card-cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: var(--app-component-bg);

    & .widget-card-cover-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40%;
      height: 40%;
      margin: -20% 0 0 -20%;
      border-radius: 50%;
      background: rgba($black, 0.75);
      color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      & .fa {
        font-size: 1.5vw;
        line-height: 1.5vw;
      }
    }
  }

  &.square {
    padding-top: 100%;
    overflow: hidden;

    & .widget-card-content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &.bottom {
        top: auto;
      }
    }
  }
}
.inverse-mode {
  & .widget-card,
  &.widget-card {
    color: $white;

    & .widget-card-cover {
      background-color: $gray-800;
    }
  }
}
