@mixin helper-opacity($from, $to) {
  @for $i from $from through $to {
    .opacity-#{$i * 10} {
      opacity: #{$i * 0.1} !important;
    }
    .opacity-#{$i * 15 } {
      opacity: #{$i * 0.15} !important;
    }
    .text-opacity-#{$i * 10} {
      --bs-text-opacity: #{$i * 0.1};
    }
    .text-opacity-#{($i * 10) + 5} {
      --bs-text-opacity: #{($i * 0.1) + 0.05};
    }
    .bg-opacity-#{$i * 10} {
      --bs-bg-opacity: #{$i * 0.1};
    }
    .bg-opacity-#{($i * 10) + 5} {
      --bs-bg-opacity: #{($i * 0.1) + 0.05};
    }
  }
}
