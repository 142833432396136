.app-content-full-height {
  height: 100%;

  & .app-content {
    @include media-breakpoint-up(md) {
      height: calc(100vh - #{$app-header-height});
    }
    @include media-breakpoint-down(md) {
      min-height: calc(100vh - #{$app-header-height * 2 + 1});
    }
  }
  &.app-without-header,
  &.app-empty {
    & .app-content {
      @include media-breakpoint-up(md) {
        height: 100vh;
      }
    }
  }
}
