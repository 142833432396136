@mixin helper-size($className, $classProperty) {
  @for $i from 0 through 12 {
    $classInt: $i * 50;

    .#{$className}-#{$classInt}px {
      #{$classProperty}: #{$classInt}px !important;
    }
    @each $breakpoint, $value in $grid-breakpoints {
      @include media-breakpoint-up($breakpoint) {
        .#{$className}-#{$breakpoint}-#{$classInt}px {
          #{$classProperty}: #{$classInt}px !important;
        }
      }
    }
  }
  @for $i from 1 through 19 {
    $classInt: $i * 5;

    .#{$className}-#{$classInt}px {
      #{$classProperty}: #{$classInt}px !important;
    }

    @each $breakpoint, $value in $grid-breakpoints {
      @include media-breakpoint-up($breakpoint) {
        .#{$className}-#{$breakpoint}-#{$classInt}px {
          #{$classProperty}: #{$classInt}px !important;
        }
      }
    }
  }
}
