@mixin helper-color($color, $value) {
  .bg-gradient-#{$color} {
    background-color: $value !important;
    background-image: -moz-linear-gradient(
      top,
      lighten($value, 10%),
      darken($value, 5%)
    ) !important;
    background-image: -webkit-linear-gradient(
      top,
      lighten($value, 10%),
      darken($value, 5%)
    ) !important;
    background-image: linear-gradient(
      to bottom,
      lighten($value, 10%),
      darken($value, 5%)
    ) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='lighten($value, 10%)', endColorstr='darken($value, 5%)',GradientType=0 ) !important;
  }
  .theme-#{$color} {
    --app-theme: #{$value} !important;
    --app-theme-rgb: #{to-rgb($value)} !important;

    @if ($color == "yellow") {
      --app-theme-color: #{$black} !important;
      --app-theme-color-rgb: #{to-rgb($black)} !important;
    }
  }
  @if (
    $color !=
      "gray" and
      $color !=
      "gray-100" and
      $color !=
      "gray-200" and
      $color !=
      "gray-300" and
      $color !=
      "gray-400" and
      $color !=
      "gray-500" and
      $color !=
      "gray-600" and
      $color !=
      "gray-700" and
      $color !=
      "gray-800" and
      $color !=
      "gray-900"
  ) {
    @for $i from 1 through 5 {
      .bg-#{$color}-#{$i * 100} {
        --bs-bg-opacity: 1;

        background-color: rgba(
          tint-color($value, ((5 - $i) * 10%)),
          var(--bs-bg-opacity)
        ) !important;
      }
      .text-#{$color}-#{$i * 100} {
        --bs-text-opacity: 1;

        color: rgba(
          tint-color($value, ((5 - $i) * 10%)),
          var(--bs-text-opacity)
        ) !important;
      }
    }
    @for $i from 6 through 9 {
      .bg-#{$color}-#{$i * 100} {
        --bs-bg-opacity: 1;

        background-color: rgba(
          shade-color($value, ($i - 5) * 10),
          var(--bs-bg-opacity)
        ) !important;
      }
      .text-#{$color}-#{$i * 100} {
        --bs-text-opacity: 1;

        color: rgba(
          shade-color($value, ($i - 5) * 10),
          var(--bs-text-opacity)
        ) !important;
      }
    }
  }
}
