.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;

  @if $enable-rtl {
    margin-right: rem(2px);
  } @else {
    margin-left: rem(2px);
  }
}
