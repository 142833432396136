.nav {
  &.nav-tabs {
    &.nav-tabs-inverse {
      background-color: var(--bs-gray-900);
      border-radius: $border-radius $border-radius 0 0;

      & .nav-item {
        & .nav-link {
          color: rgba(var(--bs-white-rgb), 0.65);

          &:hover,
          &:focus {
            color: rgba(var(--bs-white-rgb), 1);
          }
          &.active {
            color: var(--app-component-color);
            background-color: var(--app-component-bg);
          }
        }
      }
    }
    &.nav-tabs-v2 {
      border-bottom: 1px solid var(--app-component-border-color);

      & > .nav-item {
        margin-bottom: -1px;

        & > .nav-link {
          border: none;
          border-bottom: 3px solid transparent;
          padding: $nav-link-padding-x $nav-link-padding-x $nav-link-padding-x -
            rem-default(2px);

          &:hover {
            border-bottom-color: rgba(var(--app-component-color-rgb), 0.25);
          }
          &.active {
            border-bottom-color: var(--app-component-active-bg);
            background: none;
          }
        }
        &.show {
          & > .nav-link {
            background: none;
            border-bottom-color: $gray-300;
            color: $gray-900;
          }
        }
      }
    }
  }
}
